import React, { FC, useContext, useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import styles from "./Journal.module.css";
import { Context } from "../../index";
import Modal from "../../component/Modal/Modal";
import AddReference from "../../component/Forms/AddReverence/AddReference";
import Input from "../../component/Input/Input";
import Button from "../../component/Button/Button";
import Select from "../../component/Input/Select";
import ReferencesService from "../../services/ReferencesService";

interface IProps {
  type: number;
  callback: (value: any) => void;
  diver?: any;
  validation?: boolean;
  divermethod?: string;
}

const Divers: FC<IProps> = ({
  callback,
  type,
  diver,
  validation,
  divermethod,
}) => {
  const [equipments, setEquipments] = useState([]);
  const [breathing_mixes, setBreathing_mixes] = useState([]);
  const safety_stops = [
    { id: 1, name: "Ingen deko" },
    { id: 2, name: "9m" },
    { id: 3, name: "6m" },
    { id: 4, name: "Sikkerhetsstopp 5m" },
    { id: 5, name: "3m" },
  ];
  const [breathing_air, setBreathingAir] = useState<any>({
    type: 0,
    str: 0,
    trykk: 0,
    error: false,
  });
  const [emergency_air, setEmergencyAir] = useState<any>({
    type: 0,
    str: 0,
    trykk: 0,
    error: false,
  });
  const [bailout_air, setBailoutAir] = useState<any>({
    str: 0,
    trykk: 0,
    error: false,
  });
  const [extra_time, setExtraTime] = useState<any>({
    value: 0,
    error: false,
  });
  const [surface_left_time, setSurfaceLeftTime] = useState<any>({
    value: "",
    error: false,
  });
  const [at_the_bottom_time, setAtTheBottomTime] = useState<any>({
    arrival_time: "",
    leave_time: "",
    max_depth: 0.0,
    max_time: 0,
    error: false,
  });
  const [surface_return_time, setSurfaceReturnTime] = useState<any>({
    value: "",
    error: false,
  });
  const [total_dive_time, setTotalDiveTime] = useState<any>({
    value: 0,
    error: false,
  });
  const [equipment, setEquipment] = useState<any>({
    id: "",
    name: "",
    error: false,
  });
  const [breathing_mix, setBreathingMix] = useState<any>({
    id: "",
    name: "",
    error: false,
  });
  const [safety_stop, setSafetyStop] = useState<any>({
    type: "",
    safety_stop_3m: {
      arrival_time: "",
      leave_time: "",
    },
    safety_stop_5m: {
      arrival_time: "",
      leave_time: "",
    },
    safety_stop_6m: {
      arrival_time: "",
      leave_time: "",
    },
    safety_stop_9m: {
      arrival_time: "",
      leave_time: "",
    },
    error: false,
  });
  const [state_after_dive, setStateAfterDive] = useState<any>({
    state: "",
    description: "",
    error: false,
  });
  const [comments, setComments] = useState<any>({
    value: "",
    error: false,
  });
  const [saturation_dive, setSaturationDive] = useState<any>({
    value: "",
    error: false,
  });
  const [usage_table, setUsageTable] = useState<any>({
    meter: 0,
    minutes: 0,
    error: false,
  });

  const [reload, setReload] = useState<boolean>(false);

  const [valid, setValid] = useState<boolean>(false);

  useEffect(() => {
    if (validation) {
      validations();
    }
  }, [validation]);

  function validations() {
    let valid = true;
    if (!breathing_air.type) {
      setBreathingAir({ ...breathing_air, error: true });
      valid = false;
    } else if (breathing_air.type && breathing_air.type !== 1) {
      if (!breathing_air.str && !breathing_air.trykk) {
        setBreathingAir({ ...breathing_air, error: true });
        valid = false;
      }
    }
    if (divermethod !== "Scuba") {
      if (!emergency_air.type) {
        setEmergencyAir({ ...emergency_air, error: true });
        valid = false;
      } else if (emergency_air.type && emergency_air.type !== 1) {
        if (!emergency_air.str && !emergency_air.trykk) {
          setEmergencyAir({ ...emergency_air, error: true });
          valid = false;
        }
      }
    }
    if (divermethod !== "Scuba") {
      if (!bailout_air.str || !bailout_air.trykk) {
        setBailoutAir({ ...bailout_air, error: true });
        valid = false;
      }
    }
    if (
      usage_table.meter ||
      usage_table.minutes ||
      saturation_dive.value ||
      state_after_dive.state ||
      safety_stop.type ||
      total_dive_time.value ||
      surface_return_time.value ||
      surface_left_time.value ||
      at_the_bottom_time.arrival_time ||
      at_the_bottom_time.leave_time ||
      at_the_bottom_time.max_depth ||
      at_the_bottom_time.max_time
    ) {
      if (!surface_left_time.value) {
        setSurfaceLeftTime({ ...surface_left_time, error: true });
        valid = false;
      }
      if (
        !at_the_bottom_time.arrival_time ||
        !at_the_bottom_time.leave_time ||
        !at_the_bottom_time.max_depth ||
        !at_the_bottom_time.max_time
      ) {
        setAtTheBottomTime({ ...at_the_bottom_time, error: true });
        valid = false;
      }
      if (!surface_return_time.value) {
        setSurfaceReturnTime({ ...surface_return_time, error: true });
        valid = false;
      }
      if (!total_dive_time.value) {
        setTotalDiveTime({ ...total_dive_time, error: true });
        valid = false;
      }
    }
    if (!equipment.id) {
      setEquipment({ ...equipment, error: true });
      valid = false;
    }
    if (!breathing_mix.id) {
      setBreathingMix({ ...breathing_mix, error: true });
      valid = false;
    }
    if (
      usage_table.meter ||
      usage_table.minutes ||
      saturation_dive.value ||
      state_after_dive.state ||
      safety_stop.type ||
      total_dive_time.value ||
      surface_return_time.value ||
      surface_left_time.value ||
      at_the_bottom_time.arrival_time ||
      at_the_bottom_time.leave_time ||
      at_the_bottom_time.max_depth ||
      at_the_bottom_time.max_time
    ) {
      if (!safety_stop.type) {
        setSafetyStop({ ...safety_stop, error: true });
        valid = false;
      } else if (safety_stop.type !== 1) {
        if (
          safety_stop.type === 5 &&
          (!safety_stop.safety_stop_3m.arrival_time ||
            !safety_stop.safety_stop_3m.leave_time)
        ) {
          setSafetyStop({ ...safety_stop, error: true });
          valid = false;
        } else if (
          safety_stop.type === 4 &&
          (!safety_stop.safety_stop_5m.arrival_time ||
            !safety_stop.safety_stop_5m.leave_time)
        ) {
          setSafetyStop({ ...safety_stop, error: true });
          valid = false;
        } else if (
          safety_stop.type === 3 &&
          (!safety_stop.safety_stop_6m.arrival_time ||
            !safety_stop.safety_stop_6m.leave_time ||
            !safety_stop.safety_stop_3m.arrival_time ||
            !safety_stop.safety_stop_3m.leave_time)
        ) {
          setSafetyStop({ ...safety_stop, error: true });
          valid = false;
        } else if (
          safety_stop.type === 2 &&
          (!safety_stop.safety_stop_9m.arrival_time ||
            !safety_stop.safety_stop_9m.leave_time ||
            !safety_stop.safety_stop_6m.arrival_time ||
            !safety_stop.safety_stop_6m.leave_time ||
            !safety_stop.safety_stop_3m.arrival_time ||
            !safety_stop.safety_stop_3m.leave_time)
        ) {
          setSafetyStop({ ...safety_stop, error: true });
          valid = false;
        }
      }
      if (!state_after_dive.state) {
        setStateAfterDive({ ...state_after_dive, error: true });
        valid = false;
      } else if (
        state_after_dive.state === 2 &&
        !state_after_dive.description
      ) {
        setStateAfterDive({ ...state_after_dive, error: true });
        valid = false;
      }

      if (!saturation_dive.value) {
        setSaturationDive({ ...saturation_dive, error: true });
        valid = false;
      }
      if (!usage_table.meter || !usage_table.minutes) {
        setUsageTable({ ...usage_table, error: true });
        valid = false;
      }
    }
    setValid(valid);
  }
  async function getEquipments() {
    const response = await ReferencesService.getEquipments();
    setEquipments(response.data);
  }
  async function getBreathingMixes() {
    const response = await ReferencesService.getBreathingMixes();
    setBreathing_mixes(response.data);
  }
  useEffect(() => {
    getEquipments();
    getBreathingMixes();
  }, [reload]);

  useEffect(() => {
    if (diver) {
      setBreathingAir(diver.breathing_air);
      setEmergencyAir(diver.emergency_air);
      setBailoutAir(diver.bailout_air);
      setExtraTime(diver.extra_time);
      setSurfaceLeftTime(diver.surface_left_time);
      setAtTheBottomTime(diver.at_the_bottom_time);
      setSurfaceReturnTime(diver.surface_return_time);
      setTotalDiveTime(diver.total_dive_time);
      setEquipment(diver.equipment);
      setBreathingMix(diver.breathing_mix);
      setSafetyStop(diver.safety_stop);
      setStateAfterDive(diver.state_after_dive);
      setComments(diver.comments);
      setSaturationDive(diver.saturation_dive);
      setUsageTable(diver.usage_table);
    }
  }, [diver]);

  useEffect(() => {
    setReload(!reload);
  }, [equipment, breathing_mix]);

  useEffect(() => {
    countTimeAtBottomWaypoint();
  }, [
    at_the_bottom_time.arrival_time,
    at_the_bottom_time.leave_time,
    surface_left_time.value,
    extra_time.value,
  ]);
  useEffect(() => {
    countReturnTime();
  }, [surface_left_time.value, surface_return_time.value, extra_time.value]);

  useEffect(() => {
    createObject();
  }, [
    breathing_air,
    emergency_air,
    bailout_air,
    extra_time,
    surface_left_time,
    at_the_bottom_time,
    surface_return_time,
    total_dive_time,
    equipment,
    breathing_mix,
    safety_stop,
    state_after_dive,
    comments,
    saturation_dive,
    usage_table,
    valid,
  ]);

  function countTimeAtBottomWaypoint() {
    if (
      surface_left_time.value &&
      at_the_bottom_time.arrival_time &&
      at_the_bottom_time.leave_time
    ) {
      let maxTime =
        Number(
          dateToMinutes(surface_left_time.value, at_the_bottom_time.leave_time)
        ) + Number(extra_time.value);
      setAtTheBottomTime({
        ...at_the_bottom_time,
        max_time: maxTime,
      });
    }
  }
  function countReturnTime() {
    if (surface_left_time.value && surface_return_time.value) {
      let maxTime =
        Number(
          dateToMinutes(surface_left_time.value, surface_return_time.value)
        ) + Number(extra_time.value);
      setTotalDiveTime({ ...total_dive_time, value: maxTime });
    }
  }
  function dateToMinutes(dateStart: string, dateEnd: string) {
    let start = new Date(dateStart);
    let end = new Date(dateEnd);
    let diff = end.getTime() - start.getTime();
    let minutes = Math.floor(diff / 60000);
    return minutes;
  }
  function createObject() {
    let object = {
      breathing_air: breathing_air,
      emergency_air: emergency_air,
      bailout_air: bailout_air,
      extra_time: extra_time,
      surface_left_time: surface_left_time,
      at_the_bottom_time: at_the_bottom_time,
      surface_return_time: surface_return_time,
      total_dive_time: total_dive_time,
      equipment: equipment,
      breathing_mix: breathing_mix,
      safety_stop: safety_stop,
      state_after_dive: state_after_dive,
      comments: comments,
      saturation_dive: saturation_dive,
      usage_table: usage_table,
      valid: valid,
    };
    callback(object);
  }

  return (
    <div className={styles.journal}>
      <div className={styles.journal_part}>
        <div
          className={styles.journal_part__form}
          {...(type === 1 ? { style: { background: "#e5e581" } } : {})}
        >
          <div className={styles.journal_part__item}>
            <div {...(breathing_air.error ? { className: styles.error } : {})}>
              <div className={styles.journal_part__item_element}>
                <p>Pusteluft</p>
                <Select
                  options={[
                    { id: 1, name: "Kompr" },
                    { id: 2, name: "Input" },
                  ]}
                  callback={(value: any) => {
                    setBreathingAir({
                      ...breathing_air,
                      type: value.id,
                      error: false,
                    });
                  }}
                />
              </div>
              {breathing_air.type === 2 ? (
                <div className={styles.journal_part__item_element}>
                  <div className={styles.double}>
                    <p>STR/Liter</p>
                    <Input
                      type="number"
                      value={breathing_air.str}
                      onchange={(e: any) => {
                        setBreathingAir({
                          ...breathing_air,
                          str: e.target.value,
                          error: false,
                        });
                      }}
                    />
                  </div>
                  <div className={styles.double}>
                    <p>Trykk/BAR</p>
                    <Input
                      type="number"
                      value={breathing_air.trykk}
                      onchange={(e: any) => {
                        setBreathingAir({
                          ...breathing_air,
                          trykk: e.target.value,
                          error: false,
                        });
                      }}
                    />
                  </div>
                </div>
              ) : null}
            </div>
            <div {...(emergency_air.error ? { className: styles.error } : {})}>
              <div
                className={styles.journal_part__item_element}
                {...(divermethod === "Scuba"
                  ? { style: { display: "none" } }
                  : {})}
              >
                <p>Nødluft</p>
                <Select
                  options={[
                    { id: 1, name: "Kompr" },
                    { id: 2, name: "Input" },
                  ]}
                  callback={(value: any) => {
                    setEmergencyAir({
                      ...emergency_air,
                      type: value.id,
                      error: false,
                    });
                  }}
                />
              </div>
              {emergency_air.type === 2 ? (
                <div className={styles.journal_part__item_element}>
                  <div className={styles.double}>
                    <p>STR/Liter</p>
                    <Input
                      type="number"
                      value={emergency_air.str}
                      onchange={(e: any) => {
                        setEmergencyAir({
                          ...emergency_air,
                          str: e.target.value,
                          error: false,
                        });
                      }}
                    />
                  </div>
                  <div className={styles.double}>
                    <p>Trykk/BAR</p>
                    <Input
                      type="number"
                      value={emergency_air.trykk}
                      onchange={(e: any) => {
                        setEmergencyAir({
                          ...emergency_air,
                          trykk: e.target.value,
                          error: false,
                        });
                      }}
                    />
                  </div>
                </div>
              ) : null}
            </div>
            <div
              {...(bailout_air.error ? { className: styles.error } : {})}
              {...(divermethod === "Scuba"
                ? { style: { display: "none" } }
                : {})}
            >
              <div
                className={`${styles.journal_part__item_element} ${styles.borderbottom}`}
              >
                <p className={styles.element_item_title}>Bailout</p>
              </div>
              <div
                className={`${styles.journal_part__item_element} ${styles.bordertop}`}
              >
                <div className={styles.double}>
                  <p>STR/Liter</p>
                  <Input
                    type="number"
                    value={bailout_air.str}
                    onchange={(e: any) => {
                      setBailoutAir({
                        ...bailout_air,
                        str: e.target.value,
                        error: false,
                      });
                    }}
                  />
                </div>
                <div className={styles.double}>
                  <p>Trykk/BAR</p>
                  <Input
                    type="number"
                    value={bailout_air.trykk}
                    onchange={(e: any) => {
                      setBailoutAir({
                        ...bailout_air,
                        trykk: e.target.value,
                        error: false,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div {...(extra_time.error ? { className: styles.error } : {})}>
              <div className={styles.journal_part__item_element}>
                <p>Tillegg bunntid (min.)</p>
                <Input
                  type="number"
                  value={extra_time.value}
                  onchange={(e: any) => {
                    setExtraTime({
                      ...extra_time,
                      value: e.target.value,
                      error: false,
                    });
                  }}
                />
              </div>
            </div>
            <div
              {...(surface_left_time.error ? { className: styles.error } : {})}
            >
              <div className={styles.journal_part__item_element}>
                <p>Forlot overflaten</p>
                <Input
                  type="datetime-local"
                  value={surface_left_time.value}
                  onchange={(e: any) => {
                    setSurfaceLeftTime({
                      ...surface_left_time,
                      value: e.target.value,
                      error: false,
                    });
                  }}
                />
              </div>
            </div>
            <div
              {...(at_the_bottom_time.error ? { className: styles.error } : {})}
            >
              <div
                className={`${styles.journal_part__item_element} ${styles.borderbottom}`}
              >
                <p className={styles.element_item_title}>På bunnen</p>
              </div>
              <div
                className={`${styles.journal_part__item_element} ${styles.bordertop}`}
              >
                <div className={styles.double}>
                  <p>Ankom</p>
                  <Input
                    type="datetime-local"
                    value={at_the_bottom_time.arrival_time}
                    onchange={(e: any) => {
                      setAtTheBottomTime({
                        ...at_the_bottom_time,
                        arrival_time: e.target.value,
                        error: false,
                      });
                    }}
                  />
                </div>
                <div className={styles.double}>
                  <p>Forlot</p>
                  <Input
                    type="datetime-local"
                    value={at_the_bottom_time.leave_time}
                    onchange={(e: any) => {
                      setAtTheBottomTime({
                        ...at_the_bottom_time,
                        leave_time: e.target.value,
                        error: false,
                      });
                    }}
                  />
                </div>
                <div className={styles.double}>
                  <p>Maks. dybde</p>
                  <Input
                    type="number"
                    step="0.1"
                    value={at_the_bottom_time.depth}
                    onchange={(e: any) => {
                      setAtTheBottomTime({
                        ...at_the_bottom_time,
                        max_depth: e.target.value,
                        error: false,
                      });
                    }}
                  />
                </div>
                <div className={styles.double}>
                  <p>Bunntid (min.)</p>
                  <p>{at_the_bottom_time.max_time}</p>
                </div>
              </div>
            </div>
            {safety_stop.type === 2 ? (
              <div {...(safety_stop.error ? { className: styles.error } : {})}>
                <div className={styles.journal_part__item_element}>
                  <p>Dekostopp 9m</p>
                </div>
                <div className={styles.journal_part__item_element}>
                  <div className={styles.double}>
                    <p>Ankom</p>
                    <Input
                      type="datetime-local"
                      value={safety_stop.safety_stop_9m.arrival_time}
                      onchange={(e: any) => {
                        setSafetyStop({
                          ...safety_stop,
                          safety_stop_9m: {
                            ...safety_stop.safety_stop_9m,
                            arrival_time: e.target.value,
                          },
                          error: false,
                        });
                      }}
                    />
                  </div>
                  <div className={styles.double}>
                    <p>Forlot</p>
                    <Input
                      type="datetime-local"
                      value={safety_stop.safety_stop_9m.leave_time}
                      onchange={(e: any) => {
                        setSafetyStop({
                          ...safety_stop,
                          safety_stop_9m: {
                            ...safety_stop.safety_stop_9m,
                            leave_time: e.target.value,
                          },
                          error: false,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : null}
            {safety_stop.type > 1 &&
            safety_stop.type !== 4 &&
            safety_stop.type !== 5 ? (
              <div {...(safety_stop.error ? { className: styles.error } : {})}>
                <div className={styles.journal_part__item_element}>
                  <p>Dekostopp 6m</p>
                </div>
                <div className={styles.journal_part__item_element}>
                  <div className={styles.double}>
                    <p>Ankom</p>
                    <Input
                      type="datetime-local"
                      value={safety_stop.safety_stop_6m.arrival_time}
                      onchange={(e: any) => {
                        setSafetyStop({
                          ...safety_stop,
                          safety_stop_6m: {
                            ...safety_stop.safety_stop_6m,
                            arrival_time: e.target.value,
                          },
                          error: false,
                        });
                      }}
                    />
                  </div>
                  <div className={styles.double}>
                    <p>Forlot</p>
                    <Input
                      type="datetime-local"
                      value={safety_stop.safety_stop_6m.leave_time}
                      onchange={(e: any) => {
                        setSafetyStop({
                          ...safety_stop,
                          safety_stop_6m: {
                            ...safety_stop.safety_stop_6m,
                            leave_time: e.target.value,
                          },
                          error: false,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : null}
            {safety_stop.type > 1 && safety_stop.type !== 4 ? (
              <div {...(safety_stop.error ? { className: styles.error } : {})}>
                <div className={styles.journal_part__item_element}>
                  <p>Dekostopp 3m</p>
                </div>
                <div className={styles.journal_part__item_element}>
                  <div className={styles.double}>
                    <p>Ankom</p>
                    <Input
                      type="datetime-local"
                      value={safety_stop.safety_stop_3m.arrival_time}
                      onchange={(e: any) => {
                        setSafetyStop({
                          ...safety_stop,
                          safety_stop_3m: {
                            ...safety_stop.safety_stop_3m,
                            arrival_time: e.target.value,
                          },
                          error: false,
                        });
                      }}
                    />
                  </div>
                  <div className={styles.double}>
                    <p>Forlot</p>
                    <Input
                      type="datetime-local"
                      value={safety_stop.safety_stop_3m.leave_time}
                      onchange={(e: any) => {
                        setSafetyStop({
                          ...safety_stop,
                          safety_stop_3m: {
                            ...safety_stop.safety_stop_3m,
                            leave_time: e.target.value,
                          },
                          error: false,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : null}
            {safety_stop.type === 4 ? (
              <div {...(safety_stop.error ? { className: styles.error } : {})}>
                <div className={styles.journal_part__item_element}>
                  <p>Sikkerhetsstopp 5m</p>
                </div>
                <div className={styles.journal_part__item_element}>
                  <div className={styles.double}>
                    <p>Ankom</p>
                    <Input
                      type="datetime-local"
                      value={safety_stop.safety_stop_5m.arrival_time}
                      onchange={(e: any) => {
                        setSafetyStop({
                          ...safety_stop,
                          safety_stop_5m: {
                            ...safety_stop.safety_stop_5m,
                            arrival_time: e.target.value,
                          },
                          error: false,
                        });
                      }}
                    />
                  </div>
                  <div className={styles.double}>
                    <p>Forlot</p>
                    <Input
                      type="datetime-local"
                      value={safety_stop.safety_stop_5m.leave_time}
                      onchange={(e: any) => {
                        setSafetyStop({
                          ...safety_stop,
                          safety_stop_5m: {
                            ...safety_stop.safety_stop_5m,
                            leave_time: e.target.value,
                          },
                          error: false,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : null}
            <div
              {...(surface_return_time.error
                ? { className: styles.error }
                : {})}
            >
              <div className={styles.journal_part__item_element}>
                <p>På overflaten</p>
                <Input
                  type="datetime-local"
                  value={surface_return_time.value}
                  onchange={(e: any) => {
                    setSurfaceReturnTime({
                      ...surface_return_time,
                      value: e.target.value,
                      error: false,
                    });
                  }}
                />
              </div>
            </div>
            <div
              {...(total_dive_time.error ? { className: styles.error } : {})}
            >
              <div className={styles.journal_part__item_element}>
                <p>Total dykketid (min.)</p>
                <p>{total_dive_time.value}</p>
              </div>
            </div>
          </div>
          <div className={styles.journal_part__item}>
            <div {...(equipment.error ? { className: styles.error } : {})}>
              <div className={styles.journal_part__item_element}>
                <p>Utstyr</p>
                <Select
                  options={equipments}
                  callback={(e: any) => {
                    setEquipment({
                      ...equipment,
                      id: e._id,
                      name: e.name,
                      error: false,
                    });
                  }}
                  addnew={true}
                  service={ReferencesService.addEquipment}
                />
              </div>
            </div>
            <div {...(breathing_mix.error ? { className: styles.error } : {})}>
              <div className={styles.journal_part__item_element}>
                <p>Pustegass</p>
                <Select
                  options={breathing_mixes}
                  callback={(e: any) => {
                    setBreathingMix({
                      ...breathing_mix,
                      id: e._id,
                      name: e.name,
                      error: false,
                    });
                  }}
                  addnew={true}
                  service={ReferencesService.addBreathingMix}
                />
              </div>
            </div>
            <div {...(safety_stop.error ? { className: styles.error } : {})}>
              <div className={styles.journal_part__item_element}>
                <p>Deko/sikkerhets-stopp</p>
                <Select
                  options={safety_stops}
                  callback={(e: any) => {
                    setSafetyStop({
                      ...safety_stop,
                      type: e.id,
                      error: false,
                    });
                  }}
                />
              </div>
            </div>
            <div
              {...(state_after_dive.error ? { className: styles.error } : {})}
            >
              <div className={styles.journal_part__item_element}>
                <p>Tilstand etter dykk</p>
                <Select
                  options={[
                    { id: 1, name: "Dykker Ok" },
                    { id: 2, name: "Dykker ikke Ok" },
                  ]}
                  callback={(e: any) => {
                    setStateAfterDive({
                      ...state_after_dive,
                      state: e.id,
                      error: false,
                    });
                  }}
                />
              </div>
              {state_after_dive.state === 2 ? (
                <div className={styles.journal_part__item_element}>
                  <p>Beskrivelse av tilstand</p>
                  <Input
                    type="textarea"
                    value={state_after_dive.description}
                    onchange={(e: any) => {
                      setStateAfterDive({
                        ...state_after_dive,
                        description: e.target.value,
                        error: false,
                      });
                    }}
                  />
                </div>
              ) : null}
            </div>
            <div {...(comments.error ? { className: styles.error } : {})}>
              <div className={styles.journal_part__item_element}>
                <p>Kommentarer</p>
                <Input
                  type="textarea"
                  value={comments.value}
                  onchange={(e: any) => {
                    setComments({
                      ...comments,
                      value: e.target.value,
                      error: false,
                    });
                  }}
                />
              </div>
            </div>
            <div
              {...(saturation_dive.error ? { className: styles.error } : {})}
            >
              <div className={styles.journal_part__item_element}>
                <p>Metningsgruppe</p>
                <Input
                  type="text"
                  value={saturation_dive.value}
                  onchange={(e: any) => {
                    setSaturationDive({
                      ...saturation_dive,
                      value: e.target.value,
                      error: false,
                    });
                  }}
                />
              </div>
            </div>
            <div {...(usage_table.error ? { className: styles.error } : {})}>
              <div
                className={`${styles.journal_part__item_element} ${styles.borderbottom}`}
              >
                <p className={styles.element_item_title}>Tabell benyttet</p>
              </div>
              <div
                className={`${styles.journal_part__item_element} ${styles.bordertop}`}
              >
                <div className={styles.double}>
                  <p>Meter</p>
                  <Input
                    type="number"
                    value={usage_table.meter}
                    onchange={(e: any) => {
                      setUsageTable({
                        ...usage_table,
                        meter: e.target.value,
                        error: false,
                      });
                    }}
                  />
                </div>
                <div className={styles.double}>
                  <p>Minutter</p>
                  <Input
                    type="number"
                    value={usage_table.minutes}
                    onchange={(e: any) => {
                      setUsageTable({
                        ...usage_table,
                        minutes: e.target.value,
                        error: false,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(Divers);
