import React, { createContext } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Store from "./store/store";

import "./index.css";
import Layout from "./component/Layout/Layout";
import Journal from "./pages/Journal/Journal";
import Users from "./pages/Users/Users";
import Search from "./pages/Search/Search";
import SJA from "./pages/SJA/SJA";
import References from "./pages/References/References";
import WorkInstructions from "./pages/WorkInstructions/WorckInstructions";
import Certificates from "./pages/Certificates/Certificates";
interface State {
  store: Store;
}

const store = new Store();

export const Context = createContext<State>({
  store,
});
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Context.Provider value={{ store }}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/journal" element={<Journal />} />
            <Route path="/users" element={<Users />} />
            <Route path="/search" element={<Search />} />
            <Route path="/sja" element={<SJA />} />
            <Route path="/sja/:id" element={<SJA />} />
            <Route path="/references" element={<References />} />
            <Route path="/instruction" element={<WorkInstructions />} />
            <Route path="/certificates" element={<Certificates />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Context.Provider>
  </React.StrictMode>
);
