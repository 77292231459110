import React, { FC, useEffect, useState } from "react";
import { set, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { workInstructionValidationSchema } from "../../validation/validation";
import InputFormInstruction from "../../component/Input/InputFormInstruction";
import TextAreaFormInstruction from "../../component/Input/TextAreaFormInstruction";
import Button from "../../component/Button/Button";
import FileUpload from "../../component/Files/Files";
import styles from "./WorkInstructions.module.css";
import SelectUserInstruction from "../../component/Input/SelectUserInstruction";
import WorkInstructionService from "../../services/WorkInstructionService";
import PDFService from "../../services/PDFService";
import MailService from "../../services/MailService";
import { IUser } from "../../models/IUser";
type FormData = {
  name: string;
  location: string;
  date: Date;
  client: string;
  client_rep: string;
  client_phone: string;
  depth_estimated: number;
  diving_certificate: "A" | "B";
  diving_station: string;
  distribution_list: string[];
  surface_provided: boolean;
  scuba: boolean;
  description: string;
  procedure: string;
  special_relationships: string;
  security_measures: string;
  uv_welding: boolean;
  uv_burning: boolean;
  use_of_borhammer: boolean;
  use_of_air_sucker: boolean;
  use_of_angle_cutter: boolean;
  use_of_lift_balloon: boolean;
  anhuker: boolean;
  use_of_polishing_machine: boolean;
  files: string[];
};

const WorkInstructions: FC = () => {
  const formField = [
    {
      htmlFor: "name",
      label: "Oppdrag navn (max 100 tegn)",
      type: "text",
      id: "name",
    },
    {
      htmlFor: "location",
      label: "Sted",
      type: "text",
      id: "location",
    },
    {
      htmlFor: "date",
      label: "Planlagt start: \n Dato",
      type: "date",
      id: "date",
    },
    {
      htmlFor: "duration",
      label: "Planlagt varighet - Dager",
      type: "number",
      id: "duration",
    },
    {
      htmlFor: "client",
      label: "Kunde",
      type: "text",
      id: "client",
    },
    {
      htmlFor: "client_rep",
      label: "Kunde rep.",
      type: "text",
      id: "client_rep",
    },
    {
      htmlFor: "client_phone",
      label: "Kunde tlf.",
      type: "text",
      id: "client_phone",
    },
    {
      htmlFor: "depth_estimated",
      label: "Antatt dybde (m) (max 2 tegn)",
      type: "number",
      id: "depth_estimated",
    },
    {
      htmlFor: "diving_certificate",
      label: "Nødvendig dykkersertifikat A/B",
      type: "text",
      id: "diving_certificate",
    },
    {
      htmlFor: "diving_station",
      label: "Dykkestasjon",
      type: "text",
      id: "diving_station",
    },
  ];
  const formField2 = [
    {
      htmlFor: "description",
      label: "Beskrivelse av oppdrag",
      type: "textarea",
      id: "description",
    },
    {
      htmlFor: "procedure",
      label: "Fremgangsmåte for sikker gjennomføring",
      type: "textarea",
      id: "procedure",
    },
    {
      htmlFor: "special_relationships",
      label: "Spesielle forhold",
      type: "textarea",
      id: "special_relationships",
    },
    {
      htmlFor: "security_measures",
      label: "Sikkerhetstiltak",
      type: "textarea",
      id: "security_measures",
    },
  ];
  const formField3 = [
    {
      htmlFor: "uv_welding",
      label: "UV sveising",
      type: "checkbox",
      id: "uv_welding",
    },
    {
      htmlFor: "uv_burning",
      label: "UV brenning – OXY Arch",
      type: "checkbox",
      id: "uv_burning",
    },
    {
      htmlFor: "use_of_borhammer",
      label: "Bruk av borhammer",
      type: "checkbox",
      id: "use_of_borhammer",
    },
    {
      htmlFor: "use_of_air_sucker",
      label: "Bruk av luftsug",
      type: "checkbox",
      id: "use_of_air_sucker",
    },
    {
      htmlFor: "use_of_angle_cutter",
      label: "Bruk av vinkelkutter",
      type: "checkbox",
      id: "use_of_angle_cutter",
    },
    {
      htmlFor: "use_of_lift_balloon",
      label: "Bruk av løfteballong",
      type: "checkbox",
      id: "use_of_lift_balloon",
    },
    {
      htmlFor: "anhuker",
      label: "Anhuker /riggeteknikk",
      type: "checkbox",
      id: "anhuker",
    },
    {
      htmlFor: "use_of_polishing_machine",
      label: "Bruk av poleringsmaskin",
      type: "checkbox",
      id: "use_of_polishing_machine",
    },
  ];
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm<FormData>({
    resolver: yupResolver(workInstructionValidationSchema),
  });
  const [files, setFiles] = useState<File[]>([]);
  const [saveButtonText, setSaveButtonText] = useState("Lagre");
  const [emailList, setEmailList] = useState<string[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<any[]>([]);
  const [valid, setValid] = useState(false);
  useEffect(() => {
    setValue(
      "files",
      files.map((file) => file.name)
    );
    register("files");
  }, [files, setValue]);

  const onSubmit = async (data: FormData) => {
    const formData = new FormData();

    for (const [key, value] of Object.entries(data)) {
      if (
        typeof value === "boolean" ||
        typeof value === "number" ||
        value instanceof Date
      ) {
        formData.append(key, value.toString());
      } else if (Array.isArray(value)) {
        value.forEach((v) => formData.append(key, v));
      } else {
        formData.append(key, value);
      }
    }
    files.forEach((file) => {
      formData.append("files", file);
    });

    try {
      const response = await WorkInstructionService.submitWorkInstruction(
        formData
      );
      if (response.status === 200) {
        setSaveButtonText("Lagret");
        setValid(true);
        setTimeout(() => {
          setSaveButtonText("Vente! En PDF blir generert.");
        }, 1000);
        const pdf = await PDFService.createInstructionPdf(response.data);
        if (pdf.status === 200) {
          let emails = emailList.join(",");
          setSaveButtonText("PDF laget");
          setTimeout(() => {
            setSaveButtonText("Sender epost");
          }, 1000);
          const email = await MailService.sendInstruction(emails, pdf.data);
          if (email.status === 200) {
            setSaveButtonText("Epost sendt");
            setTimeout(() => {
              resetForm();
              window.open(`./pdf/instruction_${pdf.data}.pdf`);
              setValid(false);
            }, 1000);
          } else {
            setSaveButtonText("Feil");
            setTimeout(() => {
              setSaveButtonText("Lagre");
              setValid(false);
            }, 1000);
          }
        } else {
          setSaveButtonText("Feil");
          setTimeout(() => {
            setSaveButtonText("Lagre");
            setValid(false);
          }, 1000);
        }
      } else {
        setSaveButtonText("Feil");
        setTimeout(() => {
          setSaveButtonText("Lagre");
          setValid(false);
        }, 1000);
      }
    } catch (error) {
      console.error("Server error:", error);
    }
  };

  const onErrors = (errors: any) => {
    console.log("Errors:", errors);
  };
  const handleSelectedUsersChange = (selectedUserIds: string[]) => {
    setValue("distribution_list", selectedUserIds);
    setEmailList(selectedUserIds);
  };
  const resetForm = () => {
    reset();
    setFiles([]);
    setSelectedUsers([]);
    setSaveButtonText("Lagre");
    setValid(false);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit, onErrors)} className={styles.form}>
      <h1>Arbeidsinstruks</h1>

      {formField.map((field, index) => {
        return (
          <InputFormInstruction
            key={index}
            htmlFor={field.htmlFor}
            label={field.label}
            type={field.type}
            id={field.id}
            register={register}
            errors={errors}
          />
        );
      })}
      <p>*</p>
      <SelectUserInstruction
        onChange={handleSelectedUsersChange}
        value={selectedUsers}
      />
      <p>NB: SJA må lages og gjennomgås før arbeidene starter</p>
      <InputFormInstruction
        htmlFor="surface_provided"
        label="Overflateforsynt"
        type="checkbox"
        id="surface_provided"
        register={register}
        errors={errors}
      />
      <InputFormInstruction
        htmlFor="scuba"
        label="SCUBA"
        type="checkbox"
        id="scuba"
        register={register}
        errors={errors}
      />
      <p>*</p>
      {formField2.map((field, index) => {
        return (
          <TextAreaFormInstruction
            key={index}
            htmlFor={field.htmlFor}
            label={field.label}
            type={field.type}
            id={field.id}
            register={register}
            errors={errors}
          />
        );
      })}
      <p>Dykker kvalifikasjoner</p>
      {formField3.map((field, index) => {
        return (
          <InputFormInstruction
            key={index}
            htmlFor={field.htmlFor}
            label={field.label}
            type={field.type}
            id={field.id}
            register={register}
            errors={errors}
          />
        );
      })}
      <p>Files</p>
      <FileUpload
        files={files}
        setFiles={setFiles}
        onChange={(newFiles) => setFiles(newFiles)}
      />
      <div className={styles.button}>
        <Button
          type="submit"
          text={saveButtonText}
          disabled={valid ? true : false}
          {...(valid ? { classname: "green" } : {})}
        />
        <Button type="reset" text="Reset" classname="red" onclick={resetForm} />
      </div>
    </form>
  );
};

export default WorkInstructions;
