import $api from "../http";
import { AxiosResponse } from "axios";

export default class CertService {
  static async getAll(
    page: number,
    filter: object
  ): Promise<AxiosResponse<any>> {
    return $api.post<any>(`/certificates/all/${page}`, filter);
  }

  static async updateOne(
    id: string,
    data: object
  ): Promise<AxiosResponse<any>> {
    return $api.post<any>(`/certificates/update/${id}`, data);
  }
  static async deleteOne(id: string): Promise<AxiosResponse<any>> {
    return $api.delete<any>(`/certificates/${id}`);
  }
  static async addOne(data: object): Promise<AxiosResponse<any>> {
    return $api.post<any>("/certificates/add", data);
  }
}
