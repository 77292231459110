import { FC, useState, useEffect, useContext } from "react";
import { Context } from "../../index";

import { observer } from "mobx-react-lite";
import Button from "../../component/Button/Button";
import Input from "../../component/Input/Input";
import Select from "../../component/Input/Select";
import styles from "./Search.module.css";
import UserService from "../../services/UserSevice";
import { IUser } from "../../models/IUser";
import JournalService from "../../services/JournalService";
import JournalList from "./JournalList";
import SJAList from "./SJAList";
import SJAService from "../../services/SJAService";
import CVS from "../CVS/CVS";
import WorkInstructionService from "../../services/WorkInstructionService";
import MailService from "../../services/MailService";

const Search: FC = () => {
  const { store } = useContext(Context);
  const [filter, setFilter] = useState<any>({ value: 0, name: "" });
  const [search, setSearch] = useState<any>();
  const [perriod, setPerriod] = useState<any>({ from: "", to: "" });
  const [users, setUsers] = useState<IUser[]>([]);
  const [journals, setJournals] = useState<any[]>([]);
  const [pageAhora, setPageAhora] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [sjas, setSjas] = useState<any[]>([]);
  const [instructions, setInstructions] = useState<any[]>([]);
  const [journalSearch, setJournalSearch] = useState<boolean>(false);
  const [sjaSearch, setSjaSearch] = useState<boolean>(false);
  const [instructionSearch, setInstructionSearch] = useState<boolean>(false);
  const [csvStatus, setCSVStatus] = useState<string>("Prepare a CSV file");
  const [csvData, setCSVData] = useState<any>([]);
  const [testVar, setTestVar] = useState<any>([]);
  useEffect(() => {
    if (localStorage.getItem("token")) {
      store.checkAuth();
    }
  }, []);
  useEffect(() => {
    if (filter.value) {
      setSearch("");
      setCSVData([]);
      setCSVStatus("Prepare a CSV file");
    }

    getAllUser();
  }, [filter]);
  useEffect(() => {
    setCSVData([]);
    setCSVStatus("Prepare a CSV file");
  }, [search, perriod]);

  function getAllUser() {
    UserService.fetchUsers().then((data) => {
      setUsers(data.data);
    });
  }
  async function getAllJournal(page: number = 1) {
    setJournals([]);
    JournalService.getAll(page).then((data) => {
      setJournals(data.data.journals);
      setPageAhora(data.data.curentPage);
      setTotalPages(data.data.totalPages);
    });
  }
  async function resendEmail(emails: any, number: number) {
    // emails это массив с email адресами. необходимо значения преобразовать в строку через запятую без пробелов
    const email = emails.join(",");
    const response = await MailService.sendInstruction(email, number);
  }
  async function searchJournal(page: number = 1) {
    setJournals([]);
    if (filter.value === 1) {
      JournalService.getOneByNumber(search).then((data) => {
        setJournals(data.data);
      });
    } else if (filter.value === 2) {
      JournalService.getByYear(search, page).then((data) => {
        setJournals(data.data.journals);
        setPageAhora(data.data.curentPage);
        setTotalPages(data.data.totalPages);
      });
    } else if (filter.value === 3) {
      JournalService.getByDate(search, page).then((data) => {
        setJournals(data.data.journals);
        setPageAhora(data.data.curentPage);
        setTotalPages(data.data.totalPages);
      });
    } else if (filter.value === 4) {
      JournalService.getByPeriod(perriod.from, perriod.to, page).then(
        (data) => {
          setJournals(data.data.journals);
          setPageAhora(data.data.curentPage);
          setTotalPages(data.data.totalPages);
        }
      );
    } else if (filter.value === 5) {
      JournalService.getByUser(search.id, page).then((data) => {
        setJournals(data.data.journals);
        setPageAhora(data.data.curentPage);
        setTotalPages(data.data.totalPages);
      });
    } else if (filter.value === 6) {
      JournalService.getByClient(search, page).then((data) => {
        setJournals(data.data.journals);
        setPageAhora(data.data.curentPage);
        setTotalPages(data.data.totalPages);
      });
    } else if (filter.value === 7) {
      JournalService.getByTable(search, page).then((data) => {
        setJournals(data.data.journals);
        setPageAhora(data.data.curentPage);
        setTotalPages(data.data.totalPages);
      });
    }
  }
  async function getAllSja() {
    SJAService.getEndedSja().then((data) => {
      setSjas(data.data);
    });
  }
  async function searchSja() {
    SJAService.searchByText(search).then((data) => {
      setSjas(data.data);
    });
  }
  function selectJournal() {
    setJournalSearch(true);
    setSjaSearch(false);
    setInstructionSearch(false);
    setSearch("");
  }
  function selectSja() {
    setJournalSearch(false);
    setInstructionSearch(false);
    setSjaSearch(true);
    setSearch("");
  }
  function selectInstruction() {
    setJournalSearch(false);
    setSjaSearch(false);
    setInstructionSearch(true);
    setSearch("");
  }
  async function getCSVData() {
    setCSVStatus("CSV preparation in progress...");
    if (filter.value === 1) {
      JournalService.getCSVdataByNumber(search).then((data) => {
        setCSVData(data.data);
      });
    } else if (filter.value === 2) {
      JournalService.getCSVdataByYear(search).then((data) => {
        setCSVData(data.data);
      });
    } else if (filter.value === 3) {
      JournalService.getCSVdataByDate(search).then((data) => {
        setCSVData(data.data);
      });
    } else if (filter.value === 4) {
      JournalService.getCSVdataByPeriod(perriod.from, perriod.to).then(
        (data) => {
          setCSVData(data.data);
        }
      );
    } else if (filter.value === 5) {
      JournalService.getCSVdataByUser(search.id).then((data) => {
        setCSVData(data.data);
      });
    } else if (filter.value === 6) {
      JournalService.getCSVdataByClient(search).then((data) => {
        setCSVData(data.data);
      });
    } else if (filter.value === 7) {
      JournalService.getCSVdataByTable(search).then((data) => {
        setCSVData(data.data);
      });
    } else {
      const response = await JournalService.getCSVdata();
      setCSVData(response.data);
    }
    setCSVStatus("Prepare a CSV file");
  }
  async function getAllInstruction() {
    const response = await WorkInstructionService.getAll();
    setInstructions(response.data);
  }
  async function searchInstruction() {
    setInstructions([]);
    if (filter.value === 1) {
      WorkInstructionService.getOneByNumber(search).then((data) => {
        setInstructions(data.data);
      });
    } else if (filter.value === 2) {
      WorkInstructionService.getByClient(search).then((data) => {
        setInstructions(data.data);
      });
    }
  }

  function dateToString(date: string) {
    let newDate = new Date(date);
    let year = String(newDate.getFullYear());
    let month = String(newDate.getMonth() + 1).padStart(2, "0");
    let day = String(newDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  return (
    <>
      {store.isAuth ? (
        <div>
          <div className={styles.choise}>
            <div
              onClick={selectJournal}
              {...(!journalSearch ? { className: styles.notThis } : null)}
            >
              <h1>Dykkejournal</h1>
            </div>
            <div
              onClick={selectSja}
              {...(!sjaSearch ? { className: styles.notThis } : null)}
            >
              <h1>SJA</h1>
            </div>
            <div
              onClick={selectInstruction}
              {...(!instructionSearch ? { className: styles.notThis } : null)}
            >
              <h1>Arbeidsinstruks</h1>
            </div>
          </div>
          {instructionSearch ? (
            <div className={styles.searchPanel}>
              <div>
                <Select
                  options={[
                    { value: 0, name: "Vis alle" },
                    { value: 1, name: "etter nummer" },
                    { value: 2, name: "etter kundenavn" },
                  ]}
                  context={"filter"}
                  callback={setFilter}
                />
              </div>
              <div>
                {filter.value === 1 ? (
                  <Input
                    type="number"
                    placeholder="Søk etter nummer"
                    value={search}
                    onchange={(e) => setSearch(e.target.value)}
                  />
                ) : filter.value === 2 ? (
                  <Input
                    type="text"
                    placeholder="Søk etter kundenavn"
                    value={search}
                    onchange={(e) => setSearch(e.target.value)}
                  />
                ) : filter.value === 3 ? (
                  <Input
                    type="text"
                    placeholder="Søk etter firma"
                    value={search}
                    onchange={(e) => setSearch(e.target.value)}
                  />
                ) : null}
              </div>
              <div>
                <Button
                  {...(!filter.value ? { text: "Vis alt" } : { text: "Søk" })}
                  {...(!filter.value
                    ? { callback: () => getAllInstruction() }
                    : { callback: () => searchInstruction() })}
                />
              </div>
            </div>
          ) : null}

          {journalSearch ? (
            <div className={styles.searchPanel}>
              <div>
                <Select
                  options={[
                    { value: 0, name: "Vis alle" },
                    { value: 1, name: "etter nummer" },
                    { value: 2, name: "etter år" },
                    { value: 3, name: "etter nøyaktig dato" },
                    { value: 4, name: "etter periode" },
                    { value: 5, name: "etter navn" },
                    { value: 6, name: "etter Oppdragsgiver" },
                    { value: 7, name: "etter tabell benyttet (m)" },
                  ]}
                  context={"filter"}
                  callback={setFilter}
                />
              </div>
              <div>
                {filter.value === 1 ? (
                  <Input
                    type="number"
                    placeholder="Søk etter nummer"
                    value={search}
                    onchange={(e) => setSearch(e.target.value)}
                  />
                ) : filter.value === 2 ? (
                  <Input
                    type="number"
                    placeholder="Søk etter år"
                    value={search}
                    onchange={(e) => setSearch(e.target.value)}
                  />
                ) : filter.value === 3 ? (
                  <Input
                    type="date"
                    placeholder="Søk etter dato"
                    value={search}
                    onchange={(e) => setSearch(e.target.value)}
                  />
                ) : filter.value === 4 ? (
                  <div className={styles.dateContainer}>
                    <Input
                      type="date"
                      placeholder="Fra dato"
                      value={perriod.from}
                      onchange={(e) =>
                        setPerriod({
                          ...perriod,
                          from: e.target.value,
                        })
                      }
                    />
                    <Input
                      type="date"
                      placeholder="Til dato"
                      value={perriod.to}
                      onchange={(e) =>
                        setPerriod({ ...perriod, to: e.target.value })
                      }
                    />
                  </div>
                ) : filter.value === 5 ? (
                  <Select
                    options={users}
                    context={"Bruker"}
                    component={"user"}
                    callback={setSearch}
                  />
                ) : filter.value === 6 ? (
                  <Input
                    type="text"
                    placeholder="Søk etter Oppdragsgiver"
                    value={search}
                    onchange={(e) => setSearch(e.target.value)}
                  />
                ) : filter.value === 7 ? (
                  <Input
                    type="number"
                    placeholder="Søk etter tabell benyttet (m)"
                    value={search}
                    onchange={(e) => setSearch(e.target.value)}
                  />
                ) : null}
              </div>
              <div>
                <Button
                  {...(!filter.value ? { text: "Vis alt" } : { text: "Søk" })}
                  {...(!filter.value
                    ? { callback: () => getAllJournal() }
                    : { callback: () => searchJournal() })}
                />
              </div>
              <div>
                {csvData.length ? (
                  <CVS data={csvData} />
                ) : (
                  <Button text={csvStatus} callback={() => getCSVData()} />
                )}
              </div>
            </div>
          ) : null}

          {sjaSearch ? (
            <div className={styles.searchPanel}>
              <div>
                <Input
                  type="text"
                  placeholder="Søk"
                  value={search}
                  onchange={(e) => setSearch(e.target.value)}
                />
              </div>

              <div>
                <Button
                  {...(!search ? { text: "Vis alt" } : { text: "Søk" })}
                  {...(!search
                    ? { callback: () => getAllSja() }
                    : { callback: () => searchSja() })}
                />
              </div>
            </div>
          ) : null}
          {instructions.length ? (
            <div className={styles.table}>
              {instructionSearch ? (
                <table>
                  <tbody>
                    <tr>
                      <th>Nr</th>
                      <th>Dato</th>
                      <th>Navn</th>
                      <th>Sted</th>
                      <th>Kunde</th>
                      <th>Kunde rep.</th>
                      <th></th>
                    </tr>
                    {instructions.map((instruction, index) => (
                      <tr key={index}>
                        <td>{instruction.number}</td>
                        <td>{dateToString(instruction.date)}</td>
                        <td>{instruction.name}</td>
                        <td>{instruction.location}</td>
                        <td>{instruction.client}</td>
                        <td>{instruction.client_rep}</td>
                        <td>
                          <Button
                            onclick={() =>
                              window.open(
                                `./pdf/instruction_${instruction.number}.pdf`
                              )
                            }
                            text="Til PDF"
                          />
                          <Button
                            text="Send e-post"
                            callback={() =>
                              resendEmail(
                                instruction.distribution_list,
                                instruction.number
                              )
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : null}
            </div>
          ) : null}
          {journals.length ? (
            <div className={styles.table}>
              {/* <Test data={journals} /> */}
              {journalSearch ? (
                <>
                  <table>
                    <tbody>
                      <tr>
                        <th>Nr</th>
                        <th>Date</th>
                        <th>Dykkerleder</th>
                        <th>Dykker 1</th>
                        <th>Dykker 2</th>
                        <th>Dykkerassistent</th>
                        <th>Dykkemetode</th>
                        <th>Oppdragsgiver</th>
                        <th className={styles.min}>D1 Bunntid i minnuter</th>
                        <th className={styles.min}>D2 Bunntid i minnuter</th>
                        <th className={styles.min}>D1 Tabell benyttet</th>
                        <th className={styles.min}>D2 Tabell benyttet </th>
                        <th></th>
                      </tr>
                      {journals.map((journal) => {
                        return <JournalList journal={journal} />;
                      })}
                    </tbody>
                  </table>
                  <div className={styles.pagination}>
                    {[...Array(totalPages)].map((pages, index) => {
                      return (
                        <div
                          {...(pageAhora == index + 1
                            ? { className: styles.active }
                            : null)}
                          onClick={() => {
                            setPageAhora(index + 1);
                            if (!filter.value) {
                              getAllJournal(index + 1);
                            } else {
                              searchJournal(index + 1);
                            }
                          }}
                        >
                          {index + 1}
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : null}
            </div>
          ) : null}
          {sjas.length ? (
            <div className={styles.table}>
              {sjaSearch ? (
                <table>
                  <tbody>
                    <tr>
                      <th>SJA Nr:</th>
                      <th>Prosjekt navn</th>
                      <th>Dato</th>
                      <th></th>
                    </tr>
                    {sjas.map((sja) => {
                      return (
                        <SJAList
                          sja={sja}
                          reload={() => {
                            setSjas([]);
                            getAllSja();
                          }}
                        />
                      );
                    })}
                  </tbody>
                </table>
              ) : null}
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default observer(Search);
