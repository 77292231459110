import React, { FC, useState, useEffect, useContext } from "react";
import styles from "./Certificates.module.css";
import CertService from "../../services/CertService";
import FileService from "../../services/FileService";
import { Context } from "../../index";
import { observer } from "mobx-react-lite";
import Pagination from "../../component/Pagination/Pagination";
import Button from "../../component/Button/Button";
import { text } from "stream/consumers";
interface Certificate {
  _id: string;
  type: string;
  model: string;
  serial_number: string;
  identification_number: string;
  notification: string;
  valid_date: string;
  sert_link?: string;
  quarantine: boolean;
}

const Certificates: FC = () => {
  const { store } = useContext(Context);
  const [certificates, setCertificates] = useState<Certificate[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filter, setFilter] = useState<string>("all");
  const [editingId, setEditingId] = useState<string | null>(null);
  const [editableData, setEditableData] = useState<Partial<Certificate>>({});
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [addingNew, setAddingNew] = useState(false);
  const [newCertificate, setNewCertificate] = useState<Partial<Certificate>>({
    type: "",
    model: "",
    serial_number: "",
    identification_number: "",
    notification: "",
    valid_date: "",
  });

  useEffect(() => {
    if (localStorage.getItem("token")) {
      store.checkAuth();
    }
  }, []);

  useEffect(() => {
    getCertificates();
  }, [page, filter]);

  const getCertificates = async () => {
    try {
      const filterObject = getFilterObject();
      const response = await CertService.getAll(page, filterObject);
      setCertificates(response.data.certificates);
      setTotalPages(response.data.totalPages);
    } catch (e) {
      console.log("Error fetching certificates", e);
    }
  };

  const getFilterObject = () => {
    const currentDate = new Date();
    switch (filter) {
      case "valid":
        return { quarantine: false, valid_date: { $gte: currentDate } };
      case "soon":
        const soonDate = new Date();
        soonDate.setDate(currentDate.getDate() + 30);
        return {
          quarantine: false,
          valid_date: { $gte: currentDate, $lte: soonDate },
        };
      case "expired":
        return { quarantine: false, valid_date: { $lt: currentDate } };
      case "quarantine":
        return { quarantine: true };
      default:
        return {}; // no filter
    }
  };

  const handleEditClick = (certificate: Certificate) => {
    setEditingId(certificate._id);
    setEditableData({ ...certificate });
  };

  const handleInputChange = (field: keyof Certificate, value: string) => {
    setEditableData((prev) => ({ ...prev, [field]: value }));
  };
  const handleNewFileUpload = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = e.target.files?.[0];
    if (file) {
      try {
        const fileName = `${newCertificate.serial_number || "temp"}-${
          newCertificate.valid_date || "temp"
        }.${file.name.split(".").pop()}`;
        const renamedFile = new File([file], fileName, { type: file.type });

        const formData = new FormData();
        formData.append("file", renamedFile);

        const response = await FileService.uploadFilesCertificate(
          [renamedFile],
          (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted);
          }
        );

        const filePath = `/sertificates/${fileName}`;
        setNewCertificate((prev) => ({ ...prev, sert_link: filePath }));
        console.log("File uploaded successfully");
      } catch (e) {
        console.error("Error uploading file", e);
      }
    }
  };

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && editingId) {
      try {
        const fileName = `${
          editableData.serial_number ? editableData.serial_number : Date.now()
        }-${editableData.valid_date}.${file.name.split(".").pop()}`;
        const renamedFile = new File([file], fileName, { type: file.type });

        const response = await FileService.uploadFilesCertificate(
          [renamedFile],
          (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted); // Показываем прогресс загрузки
          }
        );

        const filePath = `/sertificates/${fileName}`;
        setEditableData((prev) => ({ ...prev, sert_link: filePath }));
        console.log("File uploaded successfully");
      } catch (e) {
        console.log("Error uploading file", e);
      }
    }
  };

  const saveEdit = async () => {
    if (editingId) {
      try {
        await CertService.updateOne(editingId, editableData);
        setEditingId(null);
        getCertificates();
      } catch (e) {
        console.log("Error saving edits", e);
      }
    }
  };

  const toggleQuarantine = async (_id: string, currentQuarantine: boolean) => {
    try {
      await CertService.updateOne(_id, {
        quarantine: !currentQuarantine,
        alert: false,
        last_alert_date: null,
      });
      getCertificates();
    } catch (e) {
      console.log("Error toggling quarantine", e);
    }
  };

  const deleteCertificate = async (_id: string) => {
    if (
      window.confirm("Er du sikker på at du vil slette dette sertifikatet?")
    ) {
      try {
        await CertService.deleteOne(_id);
        getCertificates();
      } catch (e) {
        console.log("Error deleting certificate", e);
      }
    }
  };

  const getRowClass = (certificate: Certificate) => {
    const currentDate = new Date();
    const validDate = new Date(certificate.valid_date);
    const daysToExpire =
      (validDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24);

    if (certificate.quarantine) return styles.quarantine;
    if (daysToExpire < 0) return styles.expired;
    if (daysToExpire <= 30) return styles.soon;
    return "";
  };
  const handleNewInputChange = (field: keyof Certificate, value: string) => {
    setNewCertificate((prev) => ({ ...prev, [field]: value }));
  };
  const saveNewCertificate = async () => {
    try {
      await CertService.addOne(newCertificate);
      setAddingNew(false);
      setNewCertificate({
        type: "",
        model: "",
        serial_number: "",
        identification_number: "",
        notification: "",
        valid_date: "",
      });
      getCertificates();
    } catch (e) {
      console.error("Error adding new certificate", e);
    }
  };
  const cancelNewCertificate = () => {
    setAddingNew(false);
    setNewCertificate({
      type: "",
      model: "",
      serial_number: "",
      identification_number: "",
      notification: "",
      valid_date: "",
    });
  };

  return (
    <div className={styles.container}>
      <h1>Sertifikater</h1>
      <div className={styles.controls}>
        <div className={styles.filters}>
          <button onClick={() => setFilter("all")}>Alle</button>
          <button onClick={() => setFilter("valid")}>Gyldige</button>
          <button onClick={() => setFilter("soon")}>Utløper snart</button>
          <button onClick={() => setFilter("expired")}>Utløpt</button>
          <button onClick={() => setFilter("quarantine")}>Karantene</button>
        </div>
        <div>
          <Button
            onclick={() => setAddingNew(true)}
            text="Legg til nytt sertifikat"
          />
        </div>
      </div>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Kategori</th>
            <th>Modell/Merke</th>
            <th>Serienr</th>
            <th>Id nr</th>
            <th>Merknad</th>
            <th>Gyldig til</th>
            <th>Sertifikat</th>
            <th>Handlinger</th>
          </tr>
        </thead>
        <tbody>
          {addingNew && (
            <tr>
              <td>
                <input
                  value={newCertificate.type || ""}
                  onChange={(e) => handleNewInputChange("type", e.target.value)}
                  placeholder="Kategori"
                />
              </td>
              <td>
                <input
                  value={newCertificate.model || ""}
                  onChange={(e) =>
                    handleNewInputChange("model", e.target.value)
                  }
                  placeholder="Modell/Merke"
                />
              </td>
              <td>
                <input
                  value={newCertificate.serial_number || ""}
                  onChange={(e) =>
                    handleNewInputChange("serial_number", e.target.value)
                  }
                  placeholder="Serienr"
                />
              </td>
              <td>
                <input
                  value={newCertificate.identification_number || ""}
                  onChange={(e) =>
                    handleNewInputChange(
                      "identification_number",
                      e.target.value
                    )
                  }
                  placeholder="Id nr"
                />
              </td>
              <td>
                <input
                  value={newCertificate.notification || ""}
                  onChange={(e) =>
                    handleNewInputChange("notification", e.target.value)
                  }
                  placeholder="Merknad"
                />
              </td>
              <td>
                <input
                  type="date"
                  value={newCertificate.valid_date || ""}
                  onChange={(e) =>
                    handleNewInputChange("valid_date", e.target.value)
                  }
                />
              </td>
              <td>
                <input type="file" onChange={handleNewFileUpload} />
                {newCertificate.sert_link && (
                  <span>Lastet opp: {newCertificate.sert_link}</span>
                )}
              </td>
              <td>
                <Button onclick={saveNewCertificate} text="Lagre" />
                <Button onclick={cancelNewCertificate} text="Avbryt" />
              </td>
            </tr>
          )}
          {certificates.map((certificate) => (
            <tr key={certificate._id} className={getRowClass(certificate)}>
              {editingId === certificate._id ? (
                <>
                  <td>
                    <input
                      value={editableData.type || ""}
                      onChange={(e) =>
                        handleInputChange("type", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      value={editableData.model || ""}
                      onChange={(e) =>
                        handleInputChange("model", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      value={editableData.serial_number || ""}
                      onChange={(e) =>
                        handleInputChange("serial_number", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      value={editableData.identification_number || ""}
                      onChange={(e) =>
                        handleInputChange(
                          "identification_number",
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                      value={editableData.notification || ""}
                      onChange={(e) =>
                        handleInputChange("notification", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="date"
                      value={editableData.valid_date || ""}
                      onChange={(e) =>
                        handleInputChange("valid_date", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input type="file" onChange={handleFileUpload} />
                  </td>
                  <td>
                    <Button onclick={saveEdit} text="Lagre" />
                    <Button onclick={() => setEditingId(null)} text="Avbryt" />
                  </td>
                </>
              ) : (
                <>
                  <td>{certificate.type}</td>
                  <td>{certificate.model}</td>
                  <td>{certificate.serial_number}</td>
                  <td>{certificate.identification_number}</td>
                  <td>{certificate.notification}</td>
                  <td>{certificate.valid_date}</td>
                  <td>
                    {certificate.sert_link ? (
                      <a
                        href={certificate.sert_link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Se sertifikat
                      </a>
                    ) : (
                      "N/A"
                    )}
                  </td>
                  <td>
                    <Button
                      onclick={() => {
                        setAddingNew(false);
                        handleEditClick(certificate);
                      }}
                      text="Rediger"
                    />

                    {!editingId ? (
                      <>
                        <Button
                          onclick={() => {
                            toggleQuarantine(
                              certificate._id,
                              certificate.quarantine
                            );
                          }}
                          text={
                            certificate.quarantine
                              ? "Fjern karantene"
                              : "Sett i karantene"
                          }
                        />
                        <Button
                          onclick={() => deleteCertificate(certificate._id)}
                          classname="red"
                          text="Slett"
                        />
                      </>
                    ) : null}
                  </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        currentPage={page}
        totalPages={totalPages}
        onPageChange={(newPage) => setPage(newPage)}
      />
    </div>
  );
};

export default Certificates;
