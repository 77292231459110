import { FC, useContext, useState, useEffect } from "react";
import { Context } from "../../index";
import { observer } from "mobx-react-lite";
import styles from "./SJA.module.css";

import SJAService from "../../services/SJAService";
import { SJAMiddleDTO } from "../../dto/SJAMiddle-dto";
import { SJABottomDTO } from "../../dto/SJABottom-dto";
import TempSJA from "../../component/Forms/TempSJA/TempSJA";

import Input from "../../component/Input/Input";
import Button from "../../component/Button/Button";
import Select from "../../component/Input/Select";
import Modal from "../../component/Modal/Modal";
import SJAMidle from "./SJAMidle";
import SJABottom from "./SJABottom";
import UserService from "../../services/UserSevice";
import PDFService from "../../services/PDFService";
import MailService from "../../services/MailService";
import { IUser } from "../../models/IUser";
import { SJAMiddleReturnDto } from "../../dto/SJAMiddleReturn-dto";
import { SJABottomReturnDTO } from "../../dto/SJABottomReturn-dto";
import { useParams } from "react-router-dom";

const SJA: FC = () => {
  const { store } = useContext(Context);
  const [sjas, setSjas] = useState<any[]>([]);
  const [reload, setReload] = useState<boolean>(false);
  const [name, setName] = useState<any>({ value: "", error: false });
  const [client, setClient] = useState<any>({
    value: "",
    error: false,
  });
  const [users, setUsers] = useState<IUser[]>([]);
  const [sdi_user_responsible, setSdiUserResponsible] = useState<any>({
    id: "",
    name: "",
    error: false,
  });
  const [location, setLocation] = useState<any>({
    value: "",
    error: false,
  });
  const [date, setDate] = useState<any>({ value: "", error: false });
  const [client_user_responsible, setClientUserResponsible] = useState<any>({
    value: "",
    error: false,
  });
  const [doc_reference, setDocReference] = useState<any>({
    value: "",
    error: false,
  });
  const [form_midle, setFormMidle] = useState<any>([]);
  const [form_bottom, setFormBottom] = useState<any>([]);
  const [sja, setSja] = useState<any>({ id: "", error: false });
  const [tempModal, setTempModal] = useState<boolean>(false);
  const [usarTemp, setUsarTemp] = useState<boolean>(false);
  const [valid, setValid] = useState<boolean>(false);
  const [saveButtonText, setSaveButtonText] = useState<string>("Validere");
  const [updateButtonText, setUpdateButtonText] = useState<string>("Update");
  const [form_middle_error, setFormMiddleError] = useState<boolean>(false);
  const [form_bottom_error, setFormBottomError] = useState<boolean>(false);

  const { id } = useParams<{ id: string }>();
  const [editablesja, setEditableSja] = useState<Boolean>(false);
  const [oneSja, setOneSja] = useState<any>({
    number: 0,
  });

  useEffect(() => {}, [sdi_user_responsible]);
  useEffect(() => {
    if (form_midle.length > 0) {
      setFormMiddleError(false);
    }
  }, [form_midle]);
  useEffect(() => {
    if (form_bottom.length > 0) {
      setFormBottomError(false);
    }
  }, [form_bottom]);
  useEffect(() => {
    if (localStorage.getItem("token")) {
      store.checkAuth();
    }
  }, []);

  useEffect(() => {
    getUsers();
    getSja();
  }, [reload]);

  useEffect(() => {
    setReload(!reload);
  }, [sdi_user_responsible, sja]);

  useEffect(() => {
    if (sja._id) {
      setData();
    }
  }, [sja]);
  useEffect(() => {
    if (id) {
      setTimeout(() => {
        getOneSja(id);
      }, 1000);
    }
  }, [id]);
  async function getUsers() {
    try {
      const response = await UserService.fetchUsers();
      setUsers(response.data);
    } catch (e) {
      console.log(e);
    }
  }
  async function setData() {
    clearForm(true);
    setName({ value: sja.name, error: false });
    setClient({ value: sja.client, error: false });
    setSdiUserResponsible({
      id: sja.sdi_user_responsible.id,
      name: `${sja.sdi_user_responsible.name} ${sja.sdi_user_responsible.surname}`,
      error: false,
    });
    setLocation({ value: sja.location, error: false });
    setDate({ value: dateToString(sja.date), error: false });
    setClientUserResponsible({
      value: sja.client_user_responsible,
      error: false,
    });
    setDocReference({ value: sja.doc_reference, error: false });
    setFormMidle(
      sja.form_middle.map((item: any) => new SJAMiddleReturnDto(item))
    );
    setFormBottom(
      sja.form_bottom.map((item: any) => new SJABottomReturnDTO(item))
    );
  }
  async function getSja() {
    const response = await SJAService.getEditSja();
    setSjas(response.data);
    response.data.map((item: any) => {
      if (item._id === id) {
        setUseEdit(item);
      }
    });
  }
  async function save() {
    const middle_form_data = form_midle.map(
      (item: any) => new SJAMiddleDTO(item)
    );
    const bottom_form_data = form_bottom.map(
      (item: any) => new SJABottomDTO(item)
    );
    const response = await SJAService.createSJA(
      name.value,
      client.value,
      sdi_user_responsible.id,
      location.value,
      date.value,
      client_user_responsible.value,
      doc_reference.value,
      middle_form_data,
      bottom_form_data
    );
    if (response.status === 200) {
      setSaveButtonText("Lagret");
      setTimeout(() => {
        setSaveButtonText("Vente! En PDF blir generert.");
      }, 500);
      const pdf = await PDFService.createSjaPdf(response.data);
      if (pdf.status === 200) {
        window.open(`./pdf/sja_${pdf.data}.pdf`);
        let emailList = "";
        if (!id) {
          // if (sja._id) {
          // 	const delsja = await SJAService.deleteOne(sja._id);
          // }
        }
        clearForm();
        setReload(!reload);
      }
    }
  }
  async function getOneUser(id: string) {
    const reference = await UserService.getOne(id);
    return reference.data;
  }
  function clearForm(opt?: boolean) {
    setFormBottom([]);
    setFormMidle([]);
    setName({ value: "", error: false });
    setClient({ value: "", error: false });
    setSdiUserResponsible({
      id: 0,
      name: "",
      error: false,
    });
    setLocation({ value: "", error: false });
    setDate({ value: "", error: false });
    setClientUserResponsible({
      value: "",
      error: false,
    });
    setDocReference({
      value: "",
      error: false,
    });
    if (!opt) {
      setSja({ id: "", error: false });
    }
    setValid(false);
    setSaveButtonText("Validate");
    setReload(!reload);
  }
  async function saveToEdit() {
    setUsarTemp(false);

    const middle_form_data = form_midle.map(
      (item: any) => new SJAMiddleDTO(item)
    );
    const bottom_form_data = form_bottom.map(
      (item: any) => new SJABottomDTO(item)
    );
    const response = await SJAService.createSJAtoEdit(
      name.value,
      client.value,
      sdi_user_responsible.id,
      location.value,
      date.value,
      client_user_responsible.value,
      doc_reference.value,
      middle_form_data,
      bottom_form_data
    );
    if (response.status === 200) {
      setReload(!reload);
    }
  }
  function dateToString(date: string) {
    let year = String(new Date(date).getFullYear());
    let month = String(new Date(date).getMonth() + 1).padStart(2, "0");
    let day = String(new Date(date).getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  async function setUseTemp(e: any) {
    if (e.sdi_user_responsible) {
      let user = await getOneUser(e.sdi_user_responsible);
      e.sdi_user_responsible = user;
    }
    setSja(e);
    setTempModal(false);
    setUsarTemp(true);
  }

  async function update() {
    setUsarTemp(false);
    const number = sja.number;
    const id = sja._id;
    const middle_form_data = form_midle.map(
      (item: any) => new SJAMiddleDTO(item)
    );
    const bottom_form_data = form_bottom.map(
      (item: any) => new SJABottomDTO(item)
    );
    const response = await SJAService.updateSJAtoEdit(
      id,
      number,
      name.value,
      client.value,
      sdi_user_responsible.id,
      location.value,
      date.value,
      client_user_responsible.value,
      doc_reference.value,
      middle_form_data,
      bottom_form_data
    );
    if (response.status === 200) {
      setUpdateButtonText("Updated");
      setTimeout(() => {
        setUpdateButtonText("Update");
      }, 1000);
      setReload(!reload);
    }
  }
  async function updateNew() {
    setUsarTemp(false);
    const number = sja.number;
    const id = sja._id;
    const middle_form_data = form_midle.map(
      (item: any) => new SJAMiddleDTO(item)
    );
    const bottom_form_data = form_bottom.map(
      (item: any) => new SJABottomDTO(item)
    );
    const response = await SJAService.updateSJA(
      id,
      number,
      name.value,
      client.value,
      sdi_user_responsible.id,
      location.value,
      date.value,
      client_user_responsible.value,
      doc_reference.value,
      middle_form_data,
      bottom_form_data,
      sja.status
    );
    if (response.status === 200) {
      setUpdateButtonText("Updated");
      setTimeout(() => {
        setUpdateButtonText("Update");
      }, 1000);
      setReload(!reload);
    }
  }
  function validate() {
    let valid = true;
    if (!name.value) {
      valid = false;
      setName({ ...name, error: true });
    }
    if (!client.value) {
      valid = false;
      setClient({ ...client, error: true });
    }
    if (!sdi_user_responsible.id) {
      valid = false;
      setSdiUserResponsible({ ...sdi_user_responsible, error: true });
    }
    if (!location.value) {
      valid = false;
      setLocation({ ...location, error: true });
    }
    if (!date.value) {
      valid = false;
      setDate({ ...date, error: true });
    }
    if (form_midle.length === 0) {
      valid = false;
      setFormMiddleError(true);
    }
    if (form_midle.length > 0) {
      for (let i = 0; i < form_midle.length; i++) {
        if (!form_midle[i].risk_after_measures.k.value) {
          valid = false;
          const newAraay = [...form_midle];
          newAraay[i].risk_after_measures.k = {
            ...newAraay[i].risk_after_measures.k,
            error: true,
          };
          setFormMidle(newAraay);
        }
        if (!form_midle[i].risk_after_measures.s.value) {
          valid = false;
          const newAraay = [...form_midle];
          newAraay[i].risk_after_measures.s = {
            ...newAraay[i].risk_after_measures.s,
            error: true,
          };
          setFormMidle(newAraay);
        }
        if (!form_midle[i].risk_evoluation.k.value) {
          valid = false;
          const newAraay = [...form_midle];
          newAraay[i].risk_evoluation.k = {
            ...newAraay[i].risk_evoluation.k,
            error: true,
          };
          setFormMidle(newAraay);
        }
        if (!form_midle[i].risk_evoluation.s.value) {
          valid = false;
          const newAraay = [...form_midle];
          newAraay[i].risk_evoluation.s = {
            ...newAraay[i].risk_evoluation.s,
            error: true,
          };
          setFormMidle(newAraay);
        }
      }
    }
    if (form_bottom.length === 0) {
      valid = false;
      setFormBottomError(true);
    } else {
      for (let i = 0; i < form_bottom.length; i++) {
        if (!form_bottom[i].name.value) {
          valid = false;
          const newAraay = [...form_bottom];
          newAraay[i].name = {
            ...newAraay[i].name,
            error: true,
          };
          setFormBottom(newAraay);
        }
        if (!form_bottom[i].company.value) {
          valid = false;
          const newAraay = [...form_bottom];
          newAraay[i].company = {
            ...newAraay[i].company,
            error: true,
          };
          setFormBottom(newAraay);
        }
        if (!form_bottom[i].date.value) {
          valid = false;
          const newAraay = [...form_bottom];
          newAraay[i].date = {
            ...newAraay[i].date,
            error: true,
          };
          setFormBottom(newAraay);
        }
      }
    }

    if (valid) {
      setSaveButtonText("Oppret PDF og Large");
    }
    setValid(valid);
  }
  async function setUseEdit(e: any) {
    if (e.sdi_user_responsible) {
      let user = await getOneUser(e.sdi_user_responsible);
      e.sdi_user_responsible = user;
    }
    setSja(e);
    setUsarTemp(false);
  }
  async function getOneSja(id: string) {
    const response = await SJAService.getOne(id);
    if (response.data.sdi_user_responsible) {
      let user = await getOneUser(response.data.sdi_user_responsible);
      response.data.sdi_user_responsible = user;
    }
    setSja(response.data);
    setUsarTemp(false);
    setEditableSja(true);
  }
  return (
    <>
      {store.isAuth ? (
        <div className={styles.sja}>
          <div className={styles.title}>Sikker Jobb Analyse (SJA)</div>
          <div className={styles.top_form}>
            <div
              {...(name.error
                ? {
                    className: `${styles.top_form_item} ${styles.error}`,
                  }
                : { className: styles.top_form_item })}
            >
              <div className={styles.label}>Prosjekt navn:</div>
              <div className={styles.input}>
                <Input
                  type="text"
                  value={name.value}
                  onchange={(e) =>
                    setName({ value: e.target.value, error: false })
                  }
                  placeholder="Prosjekt navn"
                />
              </div>
            </div>
            <div
              {...(client.error
                ? {
                    className: `${styles.top_form_item} ${styles.error}`,
                  }
                : { className: styles.top_form_item })}
            >
              <div className={styles.label}>Kunde:</div>
              <div className={styles.input}>
                <Input
                  type="text"
                  value={client.value}
                  onchange={(e) =>
                    setClient({ value: e.target.value, error: false })
                  }
                  placeholder="Kunde"
                />
              </div>
            </div>
            <div
              {...(sdi_user_responsible.error
                ? {
                    className: `${styles.top_form_item} ${styles.error}`,
                  }
                : { className: styles.top_form_item })}
            >
              <div className={styles.label}>Ansvarlig person SDI as:</div>
              <div className={styles.input}>
                <Select
                  options={users}
                  callback={(e) =>
                    setSdiUserResponsible({
                      id: e.id,
                      name: `${e.name} ${e.surname}`,
                      error: false,
                    })
                  }
                  addnew={true}
                  component="user"
                  {...(!sdi_user_responsible.id
                    ? { clear: true }
                    : { clear: false })}
                  {...(sdi_user_responsible.id
                    ? {
                        values: {
                          id: sdi_user_responsible.id,
                          name: sdi_user_responsible.name,
                        },
                      }
                    : null)}
                />
              </div>
            </div>
            <div
              {...(location.error
                ? {
                    className: `${styles.top_form_item} ${styles.error}`,
                  }
                : { className: styles.top_form_item })}
            >
              <div className={styles.label}>Sted:</div>
              <div className={styles.input}>
                <Input
                  type="text"
                  value={location.value}
                  onchange={(e) =>
                    setLocation({
                      value: e.target.value,
                      error: false,
                    })
                  }
                  placeholder="Sted"
                />
              </div>
            </div>
            <div
              {...(date.error
                ? {
                    className: `${styles.top_form_item} ${styles.error}`,
                  }
                : { className: styles.top_form_item })}
            >
              <div className={styles.label}>Dato:</div>
              <div className={styles.input}>
                <Input
                  type="date"
                  value={date.value}
                  onchange={(e) =>
                    setDate({ value: e.target.value, error: false })
                  }
                  placeholder="Dato"
                />
              </div>
            </div>
            <div className={styles.top_form_item}>
              <div className={styles.label}>Ansvarlig person kunde:</div>
              <div className={styles.input}>
                <Input
                  type="text"
                  value={client_user_responsible.value}
                  onchange={(e) =>
                    setClientUserResponsible({
                      value: e.target.value,
                      error: false,
                    })
                  }
                  placeholder="Ansvarlig person kunde"
                />
              </div>
            </div>
            <div className={styles.top_form_item}>
              <div className={styles.label}>SJA Nr:</div>
              <div className={styles.input}>
                <Select
                  options={sjas}
                  callback={(e) => setUseEdit(e)}
                  addnew={false}
                  component="sja"
                  {...(!sja._id ? { clear: true } : { clear: false })}
                />
              </div>
            </div>
            <div className={styles.top_form_item}>
              <div className={styles.label}>Dokument referanse:</div>
              <div className={styles.input}>
                <Input
                  type="text"
                  value={doc_reference.value}
                  onchange={(e) =>
                    setDocReference({
                      value: e.target.value,
                      error: false,
                    })
                  }
                  placeholder="Dokument referanse"
                />
              </div>
            </div>
          </div>
          <div className={styles.subtitle}>
            Det anbefales at SJA'en om nodvending blir etterfulg av en toolbox
            talk (SDI-HMS-SM-003)
          </div>
          <div
            {...(form_middle_error
              ? { className: `${styles.form} ${styles.error}` }
              : { className: styles.form })}
          >
            <div className={`${styles.form_item} ${styles.form_title}`}>ID</div>
            <div className={`${styles.form_item} ${styles.form_title}`}>
              Aktivitet
            </div>
            <div className={`${styles.form_item} ${styles.form_title}`}>
              Faremoment
            </div>
            <div className={`${styles.form_item} ${styles.form_title}`}>
              Årsak(til fare)
            </div>
            <div
              className={`${styles.form_item} ${styles.form_title} ${styles.risk_title}`}
            >
              <div>Risiko Evaluering</div>
              <div className={styles.risk}>
                <div>S</div>
                <div>K</div>
                <div>R</div>
              </div>
            </div>
            <div className={`${styles.form_item} ${styles.form_title}`}>
              Risiko Reduserende Tiltak
            </div>
            <div
              className={`${styles.form_item} ${styles.form_title} ${styles.risk_title}`}
            >
              <div>Risiko Reduserende Tiltak</div>
              <div className={styles.risk}>
                <div>S</div>
                <div>K</div>
                <div>R</div>
              </div>
            </div>
            <div className={`${styles.form_item} ${styles.form_title}`}>
              Kommentar
            </div>
            <SJAMidle values={form_midle} callback={setFormMidle} />
          </div>
          <div
            {...(form_bottom_error
              ? { className: `${styles.form_bottom} ${styles.error}` }
              : { className: styles.form_bottom })}
          >
            <div className={`${styles.form_item} ${styles.form_title}`}>
              Navn
            </div>
            <div className={`${styles.form_item} ${styles.form_title}`}>
              Firma
            </div>
            <div className={`${styles.form_item} ${styles.form_title}`}>
              Dato
            </div>
            <div className={`${styles.form_item} ${styles.form_title}`}>
              Signatur
            </div>
            <SJABottom values={form_bottom} callback={setFormBottom} />
          </div>

          <div className={styles.buttons}>
            {!editablesja ? (
              <>
                <Button
                  {...(sja._id && !usarTemp
                    ? { text: updateButtonText }
                    : { text: "Large utkast" })}
                  {...(sja._id
                    ? { callback: () => update() }
                    : { callback: saveToEdit })}
                />
                <Button
                  text="Eksisterende SJA Bruk som mal"
                  callback={() => setTempModal(true)}
                />
                {/* <Button text="FClear" callback={() => clearForm()} /> */}
              </>
            ) : (
              <>
                <Button
                  {...(sja._id && !usarTemp
                    ? { text: updateButtonText }
                    : { text: "Large utkast" })}
                  {...(sja._id
                    ? { callback: () => updateNew() }
                    : { callback: saveToEdit })}
                />
              </>
            )}
            <Button
              text={saveButtonText}
              {...(!valid
                ? { callback: () => validate() }
                : {
                    callback: () => {
                      save();
                    },
                  })}
              {...(valid ? { classname: "green" } : null)}
            />
            <Button
              text="Risiko Matrise"
              callback={() => window.open("/risk_matrix.pdf")}
            />
          </div>
          <Modal
            isVisible={tempModal}
            onClose={() => setTempModal(false)}
            title={"Select a template"}
            content={<TempSJA callback={(e: any) => setUseTemp(e)} />}
          />
        </div>
      ) : null}
    </>
  );
};

export default observer(SJA);
