import { FC, useContext, useState, useEffect } from "react";
import { Context } from "../../index";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import Button from "../Button/Button";
import Modal from "../Modal/Modal";
import LoginForm from "../LoginForm/LoginForm";
import styles from "./Menu.module.css";
import logo from "./logo.png";

const Menu: FC = () => {
  const { store } = useContext(Context);
  const [modal, setModal] = useState(false);
  const [status, setStatus] = useState(navigator.onLine);
  const setOnline = () => {
    setStatus(true);
  };
  const setOffline = () => {
    setStatus(false);
  };
  useEffect(() => {
    if (localStorage.getItem("token")) {
      store.checkAuth();
    }

    window.addEventListener("offline", setOffline);
    window.addEventListener("online", setOnline);
    return () => {
      window.removeEventListener("offline", setOffline);
      window.removeEventListener("online", setOnline);
    };
  }, []);

  return (
    <div>
      {store.isAuth ? (
        <div className={styles.navigation}>
          {/* <Link to="/"> */}
          <div
            {...(status
              ? { className: `${styles.logo} ${styles.online}` }
              : { className: `${styles.logo} ${styles.offline}` })}
          >
            <img src={logo} alt="logo" />
          </div>
          {/* </Link> */}
          <Link to="/journal">
            <Button text="Dykkejournal" />
          </Link>
          <Link to="/sja">
            <Button text="SJA" />
          </Link>
          <Link to="/instruction">
            <Button text="Arbeidsinstruks" />
          </Link>
          <Link to="/users">
            <Button text="Brukere" />
          </Link>
          <Link to="/search">
            <Button text="Søk" />
          </Link>
          <Link to="/references">
            <Button text="References" />
          </Link>
          <Link to="/certificates">
            <Button text="Sertifikater" />
          </Link>
          <Link to="https://www.jottacloud.com/s/155f45a2e8507474325abd2ebb899b54a55">
            <Button text="Handlingsplan" />
          </Link>
          <Link to="https://skarholm.wixsite.com/sdi-as">
            <Button text="Intranet" />
          </Link>
          <Link to="https://oskarholm.wufoo.com/forms/zt1lx6y1mm1oow/">
            <Button text="RUH" />
          </Link>
          <Button
            text="Logg ut"
            onclick={() => {
              store.logout();
            }}
            classname="red"
          />
        </div>
      ) : (
        <div className={styles.navigation}>
          <Link to="/">
            <div className={styles.logo}>
              <img src={logo} alt="logo" />
            </div>
          </Link>
          <Button text="Logg Inn" onclick={() => setModal(true)} />
        </div>
      )}
      <Modal
        isVisible={modal}
        title="Logg Inn"
        onClose={() => setModal(false)}
        content={<LoginForm callback={() => setModal(false)} />}
        widthVh={"auto"}
        heightVh={"auto"}
      />
    </div>
  );
};

export default observer(Menu);
