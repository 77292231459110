import { FC, useContext, useState, useEffect } from "react";
import { Context } from "../../index";
import { observer } from "mobx-react-lite";
import Button from "../../component/Button/Button";
import UserService from "../../services/UserSevice";
import ReferencesService from "../../services/ReferencesService";
import SJAService from "../../services/SJAService";
import Modal from "../../component/Modal/Modal";
import { set } from "mobx";
interface IProps {
  sja: any;
  reload?: any;
}

const SJAList: FC<IProps> = ({ sja, reload }) => {
  const [number, setNumber] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [date, setDate] = useState<string>("");
  const [showModal, setShowModal] = useState<any>({
    show: false,
    id: "",
  });
  const [isVoid, setIsVoid] = useState<boolean>(false);
  useEffect(() => {
    if (sja) {
      setNumber(sja.number);
      setName(sja.name);
      setDate(dateToString(sja.date));
      if (sja.void) {
        setIsVoid(sja.void);
      }
    }
  });
  function dateToString(date: string) {
    let newDate = new Date(date);
    let year = String(newDate.getFullYear());
    let month = String(newDate.getMonth() + 1).padStart(2, "0");
    let day = String(newDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  async function setVoid(id: string) {
    await SJAService.setVoid(id);
    setIsVoid(true);
    reload && reload();
  }
  return (
    <>
      <tr key={sja._id}>
        <td>{number}</td>
        <td>
          {isVoid && (
            <span style={{ color: "red", textAlign: "left" }}>Annullert</span>
          )}{" "}
          {name}
        </td>
        <td>{date}</td>
        <td>
          <Button
            text="Til PDF"
            callback={() => {
              window.open(`./pdf/sja_${number}.pdf`);
            }}
          />
          <Button
            text="Slett"
            callback={() => {
              setShowModal({ show: true, id: sja._id });
            }}
            classname="red"
          />
          {!isVoid && (
            <>
              <Button
                text="Annuller"
                callback={() => {
                  setVoid(sja._id);
                }}
                classname="red"
              />
              <Button
                text="Rediger"
                callback={() => {
                  window.open(`./sja/${sja._id}`);
                }}
              />
            </>
          )}
        </td>
      </tr>
      <Modal
        isVisible={showModal.show}
        title="Slett SJA"
        onClose={() => {
          setShowModal({ show: false, id: "" });
        }}
        content={
          <>
            <p
              style={{
                textAlign: "center",
                fontSize: "1.5em",
                fontWeight: "bold",
                marginBottom: "1em",
              }}
            >
              Er du sikker på at du vil slette SJA?
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <Button
                text="Ja"
                callback={() => {
                  SJAService.deleteOne(showModal.id);
                  setShowModal({ show: false, id: "" });
                  reload && reload();
                }}
                classname="red"
              />
              <Button
                text="Nei"
                callback={() => {
                  setShowModal({ show: false, id: "" });
                }}
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default observer(SJAList);
