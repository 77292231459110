import React from "react";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <span
          key={i}
          onClick={() => onPageChange(i)}
          style={{
            cursor: currentPage === i ? "default" : "pointer",
            fontWeight: currentPage === i ? "bold" : "normal",
            margin: "0 5px",
            color: currentPage === i ? "black" : "blue",
          }}
        >
          {i}
        </span>
      );
    }
    return pageNumbers;
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "20px",
      }}
    >
      {currentPage > 1 && (
        <button
          onClick={() => onPageChange(currentPage - 1)}
          style={{ marginRight: "10px" }}
        >
          Forrige
        </button>
      )}

      {renderPageNumbers()}

      {currentPage < totalPages && (
        <button
          onClick={() => onPageChange(currentPage + 1)}
          style={{ marginLeft: "10px" }}
        >
          Neste
        </button>
      )}
    </div>
  );
};

export default Pagination;
