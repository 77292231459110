import $api from "../http";
import { AxiosResponse } from "axios";

export default class FileService {
  static async uploadFiles(
    files: File[],
    onUploadProgress: (progressEvent: ProgressEvent) => void
  ): Promise<AxiosResponse> {
    const formData = new FormData();

    files.forEach((file) => {
      formData.append("files", file);
    });

    return $api.post("/files/upload/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  }
  static async uploadFilesCertificate(
    files: File[],
    onUploadProgress: (progressEvent: ProgressEvent) => void
  ): Promise<AxiosResponse> {
    const formData = new FormData();

    files.forEach((file) => {
      formData.append("files", file);
    });

    return $api.post("/files/upload/certificate", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  }
}
